import { combineReducers } from 'redux';
import alert from './alert';
import register from './register';
import data from './data';

export default combineReducers({
	alert,
	register,
	data,
});
